import React from "react";
import Section from "./Section";

function Profits() {
  return (
    <Section
      title="DIY KITTEN에서만 누릴 수 있는 혜택!"
      content={
        <ul className="list-disc pl-5 space-y-2 text-xl sm:text-xl text-start">
          <li>
            <strong>필요한 재료의 재고를 실시간으로 파악할 수 있어요</strong>
          </li>
          <li>
            <strong>내가 원하는 이미지로 도안을 만들 수 있어요</strong>
          </li>
          <li>
            <strong>도안에 맞는 재료를 한번에 모두 구할 수 있어요.</strong>
          </li>
          <li>
            <strong>도안을 수정할 수 있어요.</strong>
          </li>
          <li>
            <strong>시안을 확인할 수 있어요.</strong>
          </li>
        </ul>
      }
    />
  );
}

export default Profits;
