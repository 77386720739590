import React from "react";
import Header from "./components/Header";
import ImageUploader from "./components/ImageUploader";
import DIYComparison from "./components/DIYComparison";
import FutureServices from "./components/FutureSection";
import ArtistRecruitment from "./components/ArtistRecruitment";
import Footer from "./components/Footer";
import Profits from "./components/Profits";

function App() {
  return (
    <div className="App">
      <Header />
      <DIYComparison />
      <ImageUploader />
      {/* <SurveyForm /> */}
      <Profits />
      <FutureServices />
      <ArtistRecruitment />
      <Footer />
      {/* <NewsletterPopup /> */}
    </div>
  );
}

export default App;
