import React from "react";
import Section from "./Section";

function FutureServices() {
  return (
    <Section
      title="앞으로 DIY KITTEN은.."
      content={
        <ul className="list-disc pl-5 space-y-2 text-sm sm:text-base text-start">
          <li>
            <strong>10월 플리마켓 참여 예정:</strong> DIY Kitten의 다양한 제품을
            오프라인에서도 만나보세요.
          </li>
          <li>
            <strong>예약 구매 시스템:</strong> 더욱 편리하게 원하는 DIY 아이템을
            받아보세요.
          </li>
          <li>
            <strong>커뮤니티 서비스:</strong> 디자인을 공유하고, DIY에 대한
            이야기를 나눠보세요.
          </li>
          <li>
            <strong>나의 DIY 아이템 저장소:</strong> 내가 만든 아이템을
            저장하고, 언제든지 수정할 수 있어요.
          </li>
          <li>
            <strong>더 다양한 제품:</strong> 트렌디한 DIY를 만들 수 있도록
            준비할게요.
          </li>
          <li>
            <strong>디자인 판매:</strong> 사용자들에게 내 디자인을 팔아서 수익을
            얻을 수 있습니다
          </li>
          <li>
            <strong>셀러 제도:</strong> DIY KITTEN에서 갖고 있지 않은 재료는
            셀러로부터 쉽게 구해보세요.
          </li>
        </ul>
      }
    />
  );
}

export default FutureServices;
