import React from "react";

function ArtistRecruitment() {
  return (
    <section className="section-wrapper artist-recruitment bg-white shadow-md rounded-lg p-6 mb-8 w-full">
      <h2 className="text-xl font-semibold mb-4 text-center">
        ⭐️ DIY KITTEN과 계약을 맺을 재료 수급 도매업체, 비즈니스 파트너를 구합니다 ⭐️
      </h2>
      <p className="text-sm sm:text-base mb-4 text-center">
        diykitten.com@gmail.com로 문의 바랍니다 {":)"}
      </p>
    </section>
  );
}

export default ArtistRecruitment;
