import React from "react";
import Card from "./Card";
import BeadsCurtain from "./beads-curtain.gif"; // Your image path
import CryCat from "./crycat.gif";
import Section from "./Section";
function DIYComparison() {
  const sections = [
    {
      title: "",
      content: (
        <div className="w-full">
          <ol className="pl-5 space-y-2 text-center text-sm font-bold">
            <li>디자인 선택</li>
            ⬇️
            <li>
              일러스트레이터 학습 <br />
              (하루 이상 소요)
            </li>
            ⬇️
            <li>
              디자인 제작 <br />
              (1~2시간 소요)
            </li>
            ⬇️
            <li>
              엑셀 활용 <br />
              (색상 지정 및 개수 세기, 1시간 소요)
            </li>
            ⬇️
            <li>
              동대문 탐방
              <br /> (1~2시간 소요)
            </li>
            ⬇️
            <li>
              가게 탐색 <br />
              (최대 7곳 방문, 2시간 소요)
            </li>
            <li>
              직구 사이트 이용시 기본구매수량때문에
              <br />
              지출이 더 커짐.
              <br />
              <p>.</p>
              <p>.</p>
              <p>.</p>
            </li>
            <p className="mt-4 text-4xl sm:text-3xl">언제 만들지?</p>
          </ol>
        </div>
      ),
      isLeftAligned: true,
      image: (
        <img
          alt="cry-cat"
          src={CryCat}
          className="rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
        />
      ),
    },
    {
      title: "DIY Kitten과 함께라면?",
      content: (
        <div className="w-full">
          <ol className="pl-5 space-y-2 text-center text-xl font-bold">
            <li>사진 선택: 5초 소요</li>
            <li>간단한 설정: 5초 소요</li>
            <li>비즈 사이즈 선택: 1초 소요</li>
            <li>추가 수정: 1분 소요</li>
            <li>키트 배송 예약: 1분</li>
            <br />
            <h1 className="text-5xl text-center masking-tape">약 2분 소요!</h1>
          </ol>
        </div>
      ),
      image: (
        <img
          alt="beads-curtain"
          src={BeadsCurtain}
          className="rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
        />
      ),
      isLeftAligned: false,
    },
    // Add more sections as needed
  ];

  return (
    <Section
      title={'"Why DIY KITTEN?"'}
      content={sections.map((section, index) => (
        <>
          <Card
            key={index}
            title={section.title}
            content={section.content}
            image={section.image}
            isLeftAligned={section.isLeftAligned}
          />
        </>
      ))}
    />
  );
}

export default DIYComparison;
