import React from "react";

function Header() {
  return (
    <header className=" border-white border-2 rounded-2xl py-8 welcome-title-border">
      <div className="container mx-auto text-center">
        <h1 className="text-5xl font-extrabold  text-white welcome-title">
          Welcome to DIY Kitten!
        </h1>
        {/* <p className="text-xl mt-4  text-white ">
          나만의 DIY 디자인을 손쉽게 만들어 드립니다
        </p> */}
      </div>
    </header>
  );
}

export default Header;
