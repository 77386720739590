import React, { useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Modal from "react-modal";

function ImageCropModal({ isOpen, onClose, imageSrc, onCrop }) {
  const cropperRef = useRef(null);

  const handleCrop = () => {
    const cropper = cropperRef.current.cropper;
    onCrop(cropper.getCroppedCanvas().toDataURL());
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Crop Image"
      className="modal"
    >
      <h2>Crop Your Image</h2>
      <Cropper
        src={imageSrc}
        style={{ height: 400, width: "100%" }}
        aspectRatio={1}
        guides={false}
        ref={cropperRef}
        maxcropboxheight={591}
        maxcropboxwidth={591}
      />
      <button onClick={handleCrop}>Crop</button>
      <button onClick={onClose}>Cancel</button>
    </Modal>
  );
}

export default ImageCropModal;
