import React from "react";
import "./Card.css"; // Import your CSS for styling

function Card({ title, content, image, isLeftAligned }) {
  return (
    <div className={`card-wrapper ${isLeftAligned ? "left" : "right"}`}>
      <div className="card-content">
        <h3 className="font-bold mb-4 text-lg text-center ">{title}</h3>
        <p className="text-sm sm:text-base">{content}</p>
      </div>
      <div className="card-image">{image}</div>
    </div>
  );
}

export default Card;
