import React, { useRef, useState } from "react";
import Section from "./Section";
import ImageCropModal from "./ImageCropModal";

function ImageUploader() {
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [beadSize, setBeadSize] = useState("2mm");
  const fileInputRef = useRef(null);
  const beadsSelect = useRef(null);
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
      setModalOpen(true);
    };
    reader.readAsDataURL(file);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleBeadSizeChange = (e) => {
    setBeadSize(e.target.value);
  };
  async function dataUrltoBlob(dataUrl) {
    const response = await fetch(dataUrl);
    const blob = await response.blob();

    const file = new File([blob], "fileName.jpg", {
      type: "image/jpeg",
      lastModified: new Date(),
    });
    return file;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (image && email) {
      // Logic to handle file upload and email submission
      const blob = await dataUrltoBlob(croppedImage);
      const formData = new FormData();
      const beadsSize = beadsSelect.current.value
      formData.append("image", blob);
      formData.append("email", email);
      formData.append(
        "pixel_width",
        beadsSize === "6mm" ? 50 : beadsSize === "8mm" ? 30 : 20
      );
      formData.append(
        "pixel_height",
        beadsSize === "6mm" ? 50 : beadsSize === "8mm" ? 30 : 20
      );
      formData.append("beads_size", beadsSelect.current.value.slice(0, 1));
      fetch(`https://diykitten-backend.site/improvement`, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            alert("제출 완료!");
          } else {
            alert(response.error);
          }
        })
        .catch((e) => {
          alert(e);
        });
    } else {
      alert("모든 정보를 입력하세요");
    }
  };

  const handleCrop = (croppedImageDataUrl) => {
    setCroppedImage(croppedImageDataUrl);
    setModalOpen(false);
  };

  const handleCloseModal = () => {
    setImage(null); // Clear the image state if the modal is closed without cropping
    setModalOpen(false);
    resetFileInput(); // Clear the file input
  };

  const resetFileInput = () => {
    if (!croppedImage) {
      fileInputRef.current.value = null; // Clear the file input
    }
  };
  return (
    <Section
      title="Upload Your Design"
      content={
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <p className="text-lg mb-5 text-center">
              간단한 몇 가지 단계를 통해 자신만의 비즈발을 메일로 받아보세요.
              <br />
              메일의 설문조사에 참여하면 다양한 경품을 받아볼 수 있습니다.
            </p>
            <p></p>
            <label className="block text-sm font-medium">사진 업로드</label>
            <input
              type="file"
              onChange={handleImageUpload}
              className="mt-2 block w-full"
              ref={fileInputRef}
            />
          </div>
          <ImageCropModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            imageSrc={image}
            onCrop={handleCrop}
          />
          {croppedImage && (
            <div>
              <h3>Cropped Image:</h3>
              <img src={croppedImage} alt="Cropped" />
            </div>
          )}

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              비즈 사이즈 선택
            </label>
            <select
              value={beadSize}
              onChange={handleBeadSizeChange}
              className="mt-2 block w-full"
              ref={beadsSelect}
            >
              <option value="2mm">2mm {"(20x20 열쇠고리형)"}</option>
              <option value="6mm">6mm {"(50x50 벽걸이형)"}</option>
              <option value="8mm">8mm {"(30x30 벽걸이형)"}</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium">Email:</label>
            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              className="mt-2 block w-full"
              placeholder="Enter your email"
            />
          </div>
          <button className="button-mirror-ball !text-xl border-white">
            Submit
          </button>
        </form>
      }
    />
  );
}

export default ImageUploader;
