import React from "react";

const Footer = () => {
  return (
    <footer className="bg-pink-200 p-5 text-center ">
      <p className="mb-2">© 2024 DIY Kitten. All rights reserved.</p>
      <div>
        <p>Contact: diykitten.com@gmail.com</p>
        <a href="https://open.kakao.com/o/sTN78IIg" className="text-yellow-200">
          KakaoTalk
        </a>
        <br />
        <a
          href="https://www.linkedin.com/in/eunsun-namgung-975391186/"
          className="text-blue-500"
        >
          Linkedin
        </a>
      </div>
    </footer>
  );
};

export default Footer;
