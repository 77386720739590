import React from "react";

function Section({ title, content, image, cta }) {
  return (
    <section className="section-wrapper text-gray-900 py-12 ">
      <div className="container mx-auto flex flex-col items-center text-center">
        <div className="gradient-container">
          <span className="gradient-text ">{title}</span>
        </div>
        <div className="flex items-center justify-center">{image}</div>
        <div className="text-lg mb-6">{content}</div>
        {cta}
      </div>
    </section>
  );
}

export default Section;
